import { Field } from "components/common/form/Field";
import { Formik, FormikHelpers } from "formik";
import * as yup from "yup";
import { FieldError } from "components/common/form/FieldError";
import { PostSample, Sample } from "api/samples/models";
import { useMutation } from "hooks/useMutation";
import { useTrigger } from "hooks/useTrigger";
import { postSample } from "api/samples/calls";
import { useQueryClient } from "react-query";
import { FieldDate } from "components/common/form/FieldDate";
import { samplesKeys } from "api/samples/keys";
import { useSamples } from "api/samples/hooks";
import { dateFns } from "utilities/dateFns";
import { useState } from "react";
import { getAnyErrorKey } from "utilities/getAnyErrorKey";
import { useHistory } from "react-router-dom";
import { postPollResults } from "api/poll/calls";
import { PostPoll } from "api/poll/models";
import { useToastr } from "components/utils/toastr/ToastrController";
import { Assign } from "utility-types";
import { Captcha } from "components/common/captcha/Captcha";
import { fileFactory, validateReport } from "api/reports/calls";
import { useAuthStore } from "stores/auth";
import { fileDownloader } from "fileDownloader";

const validationSchema: yup.SchemaOf<PostSample> = yup.object({
  name: yup.string().required("To pole jest wymagane"),
  code: yup.string().required("To pole jest wymagane"),
  samplingDate: yup.string().required("To pole jest wymagane").nullable(),
  captcha: yup.string().required("Kliknij powyższe pole, by przejść dalej").nullable(),
});

export const Dashboard = () => {
  const queryClient = useQueryClient();
  const [addingSampleStatus, setAddingSampleStatus] = useState(false);
  const history = useHistory();
  const toastr = useToastr();
  const [submitCount, triggerSubmitCount] = useTrigger();
  const me = useAuthStore((store) => store.user);

  const search = "";
  const {
    data: samples,
    isLoading: inProgress,
    error,
  } = useSamples(samplesKeys.samples(), search, {
    onSuccess: (data) => {
      if (!data.length) {
        setAddingSampleStatus(true);
      }
    },
  });

  async function handleDownloadPDF(code: string) {
    const { url, name } = fileFactory.reportPdf(code);
    const response = await fileDownloader({
      url,
      name,
      type: "pdf",
    });
    if (response.status === "success") {
    } else {
      toastr.warning({
        title: "Oj, coś nie tak.",
        text: getAnyErrorKey(response.error),
      });
    }
  }

  const createSampleMutation = useMutation(
    ({ values }: { values: PostSample; helpers: FormikHelpers<PostSample> }) => postSample(values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(samplesKeys.samples());
        setAddingSampleStatus(false);
      },
      onError: (error, { helpers }) => {
        helpers.setErrors(error);
        triggerSubmitCount();
      },
      onSettled: (_payload, _err, { helpers }) => {
        helpers.setSubmitting(false);
      },
    },
  );

  const createPollMutation = useMutation(
    (args: Assign<PostPoll, { poll: string }>) => postPollResults(args),
    {
      onSuccess: (payload, { poll }) => {
        history.push(`/poll/${payload.id}/${poll}`);
      },
      onError: (err) => {
        toastr.neutral({
          title: "",
          text: getAnyErrorKey(err),
        });
      },
    },
  );

  const validateMutation = useMutation(
    ({
      values,
      code,
    }: {
      values: { birthday: string };
      helpers: FormikHelpers<{ birthday: string }>;
      code: string;
    }) => {
      const data = dateFns.format(new Date(values.birthday), "yyyy-MM-dd");
      return validateReport({ birthday: data }, code);
    },
    {
      onSuccess: (payload, { code }) => {
        queryClient.invalidateQueries(samplesKeys.samples());
        if (!payload.isVerified) {
          toastr.neutral({
            title: "",
            text: "Podana data urodzenia nie zgadza się z datą przekazaną wraz z próbką.",
          });
        } else {
          history.push("/reports/" + code);
        }
      },
      onError: (err, { helpers }) => {
        helpers.setErrors(err);
      },
      onSettled: (_payload, _err, { helpers }) => {
        helpers.setSubmitting(false);
      },
    },
  );

  if (inProgress) {
    return (
      <div className="d-flex align-items-center justify-content-center">
        <div className="mainContainer">Ładowanie...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="d-flex align-items-center justify-content-center">
        <div className="mainContainer">Wystąpił błąd: {getAnyErrorKey(error)}</div>
      </div>
    );
  }

  const initialValues: PostSample = { name: "", code: "", samplingDate: "", captcha: "" };

  const pollStatus = (sample: Sample) => {
    if (sample.isFromResearchStage) {
      return (
        <div className=" d-flex flex-column align-items-center justify-content-center mt-4 pt-2 ">
          <button className="btnSecondary px-2 mb-2" onClick={() => handleDownloadPDF(sample.code)}>
            Pobierz raport
          </button>
          <button
            className="btnSecondary px-2"
            onClick={() => history.push("/krona/" + sample.code)}
          >
            Interaktywna przeglądarka mikrobiomu
          </button>
          {sample.hasTable && <button
              className="btnSecondary px-2"
              onClick={() => history.push("/table/" + sample.code)}
            >
              Tabela składu mikrobiomu
            </button>}
        </div>
      );
    }
    
    if (sample.status === "report_simple") {
      return (
        <div className=" d-flex flex-column align-items-center justify-content-center mt-4 pt-2 ">
          <button
            className="btnSecondary px-2 mb-2"
            onClick={() => history.push(`/reports/${sample.code}`)}
          >
            Raport wynikowy
          </button>
        </div>
      );
    }

    if ((sample.status === "report_ready" || sample.status === "report_ready_table") && !sample.isReportVerified) {
      return (
        <Formik
          initialValues={{ birthday: "" }}
          onSubmit={(
            values: { birthday: string },
            helpers: FormikHelpers<{ birthday: string }>,
          ) => {
            validateMutation.mutate({ values, helpers, code: sample.code });
          }}
          validationSchema={yup.object({
            birthday: yup.string().required("To pole jest wymagane").nullable(),
          })}
        >
          {({ isSubmitting, handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit} autoComplete="off">
                <div className="info mt-4">
                  Twój raport jest gotowy, podaj datę urodzenia aby go wyświetlić
                </div>

                <div className="pt-2">
                  <FieldDate
                    name="birthday"
                    placeholder="Data urodzenia"
                    placement="bottom-end"
                    format="dd.MM.yyyy"
                    showYearDropdown
                    showMonthDropdown
                    showTimeInput={false}
                  />
                  <FieldError name="detail" />
                </div>
                <div className="d-flex align-items-center justify-content-center flex-column mt-4">
                  <button className="btnPrimary" type="submit" disabled={isSubmitting}>
                    Zobacz raport
                  </button>
                </div>
              </form>
            );
          }}
        </Formik>
      );
    }

    if (sample.status === "report_ready" || sample.status === "report_ready_table" ) {
      return (
        <div className=" d-flex flex-column align-items-center justify-content-center mt-4 pt-2 ">
          <button
            className="btnSecondary px-2 mb-2"
            onClick={() => history.push(`/reports/${sample.code}`)}
          >
            Raport wynikowy
          </button>
          <button
            className="btnSecondary px-2"
            onClick={() => history.push("/krona/" + sample.code)}
          >
            Interaktywna przeglądarka mikrobiomu
          </button>
          {(sample.status === "report_ready_table" ) && <button
              className="btnSecondary px-2"
              onClick={() => history.push("/table/" + sample.code)}
            >
              Tabela składu mikrobiomu
            </button>}
          {samples.map((el) => el.isAnalysed).filter(Boolean).length >= 2 && (
            //If you want to enable visibility of button remove d-none
            <button className="btnSecondary px-2 mt-2 d-none" onClick={() => {}}>
              Porównaj swoje wyniki
            </button>
          )}
        </div>
      );
    }

    if (!sample.pollResult) {
      return (
        <div className=" d-flex flex-column align-items-center justify-content-center mt-4 pt-2 ">
          <SampleStatus status={sample.status} />
          <button
            className="btnPrimary px-2 mt-2"
            onClick={() =>
              createPollMutation.mutate({
                is_done: false,
                data: {},
                sample: sample.id,
                poll: sample.poll,
              })
            }
          >
            Uzupełnij ankietę
          </button>
        </div>
      );
    }

    if ( !sample.pollResult.isDone) {
      return (
        <div className=" d-flex flex-column align-items-center justify-content-center mt-4 pt-2 ">
          <SampleStatus status={sample.status} />
          <button
            className="btnPrimary px-2 mt-2"
            onClick={() => history.push(`/poll/${sample.pollResult?.id}/${sample.poll}`)}
          >
            Kontynuuj ankietę
          </button>
        </div>
      );
    }
    return (
      <div className=" d-flex flex-column align-items-center justify-content-center mt-4 pt-2 ">
        <SampleStatus status={sample.status} />
      </div>
    );
  };

  return (
    <div className="d-flex align-items-center justify-content-center">
      <div className="mainContainer">
        {!Boolean(samples.length) && (
          <div className="row ms-0 ps-0">
            <div className=" col-12 col-md-6 col-lg-4 d-flex align-items-start pb-4 px-0">
              <h4 className="fw-bold">Nie masz jeszcze przypisanych próbek</h4>
            </div>
          </div>
        )}
        <div className="row">
          {samples.map((el) => (
            <div className="col-12 col-md-6 col-lg-4 mb-4" key={el.id}>
              <div className="formBox p-4">
                {el.isFromResearchStage ? (
                  <div className="label">Badanie z naukowego etapu projektu</div>
                ) : (
                  <>
                    <div className="label">badana osoba</div>
                    <h5 className="pb-3">{el.name}</h5>
                  </>
                )}
                <div className="d-flex justify-content-between flex-wrap mt-3">
                  <div className="mb-3 mb-md-0">
                    <div className="label">unikalny kod badania</div>
                    <div className="probeNumber">{el.code}</div>
                  </div>
                  {!el.isFromResearchStage && (
                    <div className="d-flex flex-column align-items-end">
                      <div className="label">data pobrania</div>
                      <div>{dateFns.format(new Date(new Date(el.samplingDate)), "dd.MM.yyyy")}</div>
                    </div>
                  )}
                </div>
                {pollStatus(el)}
              </div>
            </div>
          ))}
          {addingSampleStatus ? (
            <div className="col-12 col-md-6 col-lg-4 mb-3">
              <div className="formBox p-4">
                <h5 className="pb-2">Dodawanie nowego badania</h5>
                <Formik
                  initialValues={initialValues}
                  onSubmit={(values: PostSample, helpers: FormikHelpers<PostSample>) =>
                    createSampleMutation.mutateAsync({ values, helpers })
                  }
                  validationSchema={validationSchema}
                >
                  {({ isSubmitting, handleSubmit, setFieldValue }) => {
                    return (
                      <form onSubmit={handleSubmit} autoComplete="off">
                        <div className="pb-2">
                          <Field<PostSample>
                            name="name"
                            placeholder="Badana osoba"
                            inputClassName="input"
                          />
                        </div>
                        <div className="pb-2">
                          <Field<PostSample>
                            name="code"
                            placeholder="Unikalny kod badania"
                            inputClassName="input"
                          />
                        </div>
                        <div className="pb-2">
                          <FieldDate<PostSample>
                            name="samplingDate"
                            placeholder="Data pobrania"
                            placement="bottom-end"
                            format="dd.MM.yyyy HH:mm"
                          />
                          <FieldError name="detail" />
                        </div>
                        <Captcha
                          key={submitCount}
                          callback={(payload) => {
                            setFieldValue("captcha", payload);
                          }}
                        />
                        <FieldError name="captcha" />
                        <div className="d-flex align-items-center justify-content-center flex-column mt-4">
                          <button className="btnPrimary" type="submit" disabled={isSubmitting}>
                            Dodaj{isSubmitting && "..."}
                          </button>
                        </div>
                      </form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          ) : (
            <div className="col-12 col-md-6 col-lg-4 mb-4">
              <button className="addBtn" onClick={() => setAddingSampleStatus(true)}>
                <div className="plus">+</div>
                <div>Dodaj nowe badanie</div>
              </button>
            </div>
          )}
          {!Boolean(samples.length) && (
            <div className="col-12 col-md-6 col-lg-8 ps-4 mb-3">
              <div className="ctaBox">
                <div>
                  Nie zakupiłeś jeszcze <strong>Nanobiome</strong>
                  <span className="moreInfo">?</span>
                  <br />
                  Możesz to zrobić{" "}
                  <a
                    rel="noopener noreferrer"
                    href="https://nanobiome.pl/badanie-mikrobioty/"
                    target="_blank"
                  >
                    <strong>tutaj</strong>
                  </a>
                </div>
                <div className="arrow">
                  <img src="../images/arrow.png" alt="" />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
const SampleStatus = ({ status }: { status: Sample["status"] }) => {
  return (
    <div className=" d-flex flex-column align-items-center justify-content-center">
      <h5>{status === "awaiting_for_sample" && "Oczekujemy na Twoją próbkę"}</h5>
      <h5>{status === "analysis_in_progress" && "W trakcie analizy..."}</h5>
    </div>
  );
};
