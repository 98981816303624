import { useState } from "react";
import sygnet from "assets/images/sygnetNB.png";
import { Field } from "components/common/form/Field";
import { Formik, FormikHelpers } from "formik";
import { Link } from "react-router-dom";
import * as yup from "yup";
import { postUser } from "api/users/calls";
import { BackendErrorDict } from "api/connector";
import { Text } from "components/common/text/Text";
import { FieldCheckbox } from "components/common/form/FormCheckbox";

type Values = {
  email: string;
  password: string;
  confirmPassword: string;
  rodo: boolean;
};

const validationSchema: yup.SchemaOf<Values> = yup.object({
  email: yup.string().email("Wpisz poprawny adres e-mail").required("Wpisz adres e-mail"),
  password: yup.string().min(8, "Hasło powinno mieć co najmniej 8 znaków").required("Wpisz hasło"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Wpisane hasła nie pasują do siebie!")
    .required("Wpisz hasło ponownie, by je potwierdzić"),
  rodo: yup.boolean().required("To pole jest wymagane").oneOf([true], "To pole jest wymagane"),
});

export const Register = () => {
  const [successRegistration, setSuccessRegistration] = useState(false);
  async function submit(values: Values, helpers: FormikHelpers<Values>) {
    try {
      await postUser({
        ...values,
        firstName: "imię",
        lastName: "nazwisko",
      });
      setSuccessRegistration(true);
    } catch (error) {
      helpers.setErrors(error as BackendErrorDict);
    }
  }
  const initialValues: Values = { email: "", password: "", confirmPassword: "", rodo: false };

  return (
    <Formik initialValues={initialValues} onSubmit={submit} validationSchema={validationSchema}>
      {({ isSubmitting, handleSubmit }) => {
        return (
          <form onSubmit={handleSubmit} autoComplete="off">
            <div className="d-flex align-items-center justify-content-center h-100">
              <div className="w-50">
                <div className="d-flex align-items-start pb-5">
                  <img className="sygnetSmall" src={sygnet} alt="" />
                  <Text fontSize="1.65rem">Panel pacjenta</Text>
                </div>

                {successRegistration ? (
                  <div className="row d-flex flex-wrap align-items-center">
                    <div className="col-12">
                      <h3>Użytkownik został zarejestrowany pomyślnie.</h3>
                      <h5>
                        Na podany przez Ciebie adres e-mail została wysłana wiadomość z linkiem
                        aktywacyjnym. Kliknij w link, aby potwierdzić zgodę.
                      </h5>
                      <div className="mt-4">
                        <Link to="/login" className="btnSecondary px-2" type="submit">
                          Zaloguj się
                        </Link>
                      </div>
                      <h5>
                        Nie dotarł do Ciebie link? Kliknij poniżej
                      </h5>

                      <div>
                        <button className="btnPrimary px-2" type="submit" disabled={isSubmitting}>
                          Wyślij ponownie{isSubmitting && "..."}
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="row d-flex">
                    <div className="col-12 col-md-8 pe-4">
                      <div className="pb-2">
                        <Field<Values> name="email" placeholder="e-mail" inputClassName="input" />
                        <Field<Values>
                          name="password"
                          placeholder="hasło"
                          type="password"
                          inputClassName="input"
                        />
                        <Field<Values>
                          name="confirmPassword"
                          placeholder="powtórz hasło"
                          type="password"
                          inputClassName="input"
                        />
                        <FieldCheckbox<Values>
                          name="rodo"
                          text={
                            <Text fontSize="14">
                              Wyrażam zgodę na przetwarzanie moich danych osobowych w zakresie
                              wynikającym ze zleconych badań oraz w celach do nich przypisanych
                              przez genXone Spółka Akcyjna. Jednocześnie oświadczam, że
                              zapoznałam/zapoznałem się z 
                              <a href="https://nanobiome.pl/regulamin/">Regulaminem Serwisu</a> i w
                              pełni go akceptuję.
                            </Text>
                          }
                        />
                      </div>
                      <div>
                        <button className="btnPrimary px-2" type="submit" disabled={isSubmitting}>
                          Zarejestruj się{isSubmitting && "..."}
                        </button>
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <h5 className="mt-2">Masz już konto?</h5>
                      <div className="mt-3">
                        <Link to="/login" className="btnSecondary px-2" type="submit">
                          Zaloguj się
                        </Link>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};
